.rs-slider {
    &.style1 {
        position: relative;

        &.aboutSlider {
            .slick-arrow {
                background: $orangeColor;
                border-radius: 50%;
                z-index: 1;
                &.slick-next{
                    right: 25px;   
                }
                &.slick-prev{
                    left: 25px;
                }
            }
        }

        .slider-content {
            padding: 0px 0;

            .sl-sub-title {
                font-size: 48px;
                line-height: 58px;
                font-family: $titleFont;
                margin-bottom: 24px;
            }

            .sl-title {
                font-size: 90px;
                line-height: 100px;
                margin-bottom: 39px;
                margin-left: -5px;
            }

            &.slide1 {
                // background: url(../img/slider/h2-1.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.slide2 {
                // background: url(../img/slider/h2-2.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .owl-nav {

            .owl-next,
            .owl-prev {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 30px;
                width: 42px;
                height: 42px;
                line-height: 42px;
                border-radius: 50%;
                background: $primaryColor;
                text-align: center;
                color: $whiteColor;
                transition: all 0.5s;
                transition-delay: 0.70s;
                opacity: 0;
                visibility: hidden;

                i {
                    &:before {
                        content: "\f138";
                        font-family: Flaticon;
                    }
                }
            }

            .owl-next {
                right: 30px;
                left: unset;

                i {
                    &:before {
                        content: "\f137";
                    }
                }
            }
        }

        &:hover {
            .owl-nav {

                .owl-next,
                .owl-prev {
                    left: 50px;
                    transition-delay: 0s;
                    visibility: visible;
                    opacity: 1;
                }

                .owl-next {
                    right: 50px;
                    left: unset;
                }
            }
        }
    }

    &.style2 {
        .slide-part {
            background: #f9f8f8;
            padding: 100px 0 0;

            .img-part {
                margin-bottom: -80px;
                overflow: hidden;

            }

            .content {
                padding: 100px 0 100px;

                .title {
                    font-size: 55px;
                    line-height: 70px;
                    font-family: $titleFont;
                    margin-bottom: 40px;
                    color: $titleColor4;
                }

                .sub-title {
                    font-size: 16px;
                    line-height: 25px;
                    color: $orangeColor;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                }
            }
        }

        .owl-nav {

            .owl-next,
            .owl-prev {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 30px;
                width: 42px;
                height: 42px;
                line-height: 42px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.1);
                cursor: pointer;
                text-align: center;
                color: $whiteColor;
                transition: all 0.5s;
                transition-delay: 0.70s;
                opacity: 0;
                visibility: hidden;

                i {
                    &:before {
                        content: "\f138";
                        font-family: Flaticon;
                    }
                }

                &:hover {
                    background: $orangeColor;
                    color: $whiteColor;
                }
            }

            .owl-next {
                right: 30px;
                left: unset;

                i {
                    &:before {
                        content: "\f137";
                    }
                }
            }
        }

        &:hover {
            .owl-nav {

                .owl-next,
                .owl-prev {
                    left: 50px;
                    transition-delay: 0s;
                    visibility: visible;
                    opacity: 1;
                }

                .owl-next {
                    right: 50px;
                    left: unset;
                }
            }
        }

        .owl-carousel .owl-stage-outer {
            padding: 0 0 80px;
            margin: 0 0 -80px;
        }
    }

    &.main-home {
        position: relative;
        padding-top: 140px;

        @media (max-width:991px) {
            padding-top: 67px;

        }

        .slider-content {
            text-align: center;
            position: relative;
            min-height: 70vh;

            @media (max-width: 991px) {
                min-height: 40vh;
            }

            &:before {
                content: "";
                background: #000;
                position: absolute;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                opacity: .4;
            }

            .bgMedia {
                position: absolute;
                height: 100%;
                width: 100%;

                img,
                video {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .content-part {
                // padding: 210px 35px 400px;
                max-width: 900px;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;

                @media (max-width: 991px) {
                    width: 80%;
                }

                .sl-sub-title {
                    font-size: 22px;
                    line-height: 30px;
                    font-weight: 500;
                    color: $orangeColor;
                    margin-bottom: 22px;
                    text-transform: uppercase;
                }

                .sl-title {
                    font-size: 50px;
                    line-height: 50px;
                    font-weight: 700;
                    color: $whiteColor;
                    margin-bottom: 65px;
                    padding: 15px;
                    background: rgba(1, 14, 39, 0.5)
                }
            }

        }

        .owl-nav {

            .owl-next,
            .owl-prev {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 30px;
                width: 42px;
                height: 42px;
                line-height: 42px;
                border-radius: 50%;
                background: $orangeColor;
                text-align: center;
                color: $whiteColor;
                transition: all 0.5s;
                transition-delay: 0.70s;
                opacity: 0;
                visibility: hidden;

                i {
                    &:before {
                        content: "\f138";
                        font-family: Flaticon;
                    }
                }

                &:hover {
                    background: darken($orangeColor, 10%);
                }
            }

            .owl-next {
                right: 30px;
                left: unset;

                i {
                    &:before {
                        content: "\f137";
                    }
                }
            }
        }

        &:hover {
            .owl-nav {

                .owl-next,
                .owl-prev {
                    left: 50px;
                    transition-delay: 0s;
                    visibility: visible;
                    opacity: 1;
                }

                .owl-next {
                    right: 50px;
                    left: unset;
                }
            }
        }
    }
}


/* Custom Container Width :1440px. */
@media (min-width: 1440px) {}

/* Laptop :1600px. */
@media #{$laptop} {}


/* XL Device :1366px. */
@media #{$xl} {}

/* Lg Device :1199px. */
@media #{$lg} {
    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 15px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 75px;
        line-height: 85px;
    }
}

/* Tablet desktop :992px to 1199px. */
@media #{$only_lg} {}

/* Tablet desktop :991px. */
@media #{$md} {
    .rs-slider.style2 .slide-part .content .title {
        font-size: 48px;
        line-height: 60px;
    }

    .rs-slider.style2 .owl-carousel .owl-stage-outer {
        margin: 0 0 -70px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 60px;
        line-height: 1.3;
    }

    .rs-slider.main-home .slider-content .content-part {
        // padding: 200px 35px 165px;
    }

    .rs-slider.style1 .slider-content {
        padding: 50px 0;
    }

    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 35px;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 70px;
        line-height: 1.2;
    }
}

/* Tablet :767px. */
@media #{$sm} {
    .rs-slider.style2 .slide-part .content .title {
        font-size: 34px;
        line-height: 44px;
    }

    .rs-slider.main-home .slider-content .content-part {
        // padding: 175px 15px 125px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-sub-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 48px;
        margin-bottom: 50px;
    }

    .rs-slider.style1 .slider-content {
        padding: 0px 0;
        
    }

    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 30px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 50px;
        margin-bottom: 30px;
    }

}

/* Large Mobile :575px. */
@media #{$xs} {
    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 25px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-sub-title {
        font-size: 18px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 35px;
        margin-bottom: 30px;
    }

    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 20px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 33px;
    }

}

/* small mobile :480px. */
@media #{$mobile} {
    .rs-slider.main-home .slider-content .content-part .sl-sub-title {
        font-size: 16px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 30px;
        margin-bottom: 25px;
    }
}