.rs-shop-part{
    .woocommerce-result-count{
        font-size: 15px;
        line-height: 26px;
        color: $bodyColor;
        font-weight: 400;
        margin: 0;
    }
    .from-control{
        float: right;
        font-size: 15px;
        color: $bodyColor;
        font-weight: 400;
        vertical-align: top;
        padding: 10px;
        border: 1px solid #ced4da;
        border-radius: 5px;

    }
    .product-list{
        .image-product{
            position: relative;
            .overlay{
                i{
                    position: absolute;
                    bottom: 150px;
                    right: 13px;
                    background: transparent;
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.4s;
                    &:before{
                        font-size: 30px;
                        line-height: 45px;
                        color: $bodyColor;
                        font-weight: 400;
                        background: $whiteColor;
                        border: none ;
                        padding: 8px 10px 8px;
                        height: 45px;
                        width: 45px;
                        line-height: 45px
                    }
                }
            }
            .onsale{
                font-size: 13px;
                color: $whiteColor;
                font-weight: 700;
                line-height: 40px;
                background:$secondaryColor;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 13px;
                right: 13px;

            }

        }
        .content-desc{
            .product-title{
                margin: 0;
                font-size: 18px;
                line-height: 50px;
                color: $bodyColor;
                font-weight: 700;
               a{
                   color: $titleColor;
                   font-size: 22px;
                   font-weight: 600;
                    &:hover{
                        color: $primaryColor;
                    }
               }
            }
            .price{
                font-size: 20px;
                line-height: 30px;
                color: $primaryColor;
                font-weight: 700;
                margin: 0;
                del {
                    opacity: 0.6;
                    padding-right: 10px;
                }
            }
            .prev-price {
                margin-right: 5px;
            }
        }
        &:hover{
            .image-product{
                .overlay i{
                    opacity: 1;
                    visibility: visible;
                    bottom: 13px;
                }
            }
        }
    }
    &.part2{
        .img-part{
            a{
                border: 1px solid $bodyColor;
                font-size: 15px;
                line-height: 26px;
                font-weight: 400;
                color: $bodyColor;
                background: $whiteColor;
            }
        }

        /*Shop Single CSS*/
        .rs-single-product {
            .tab-area {
                ul.nav-tabs {
                    margin-bottom: 25px;
                    border: none;
                        border-bottom: 1px solid #e6ebee;
                    li {
                        margin-right: 40px;
                        a {
                            display: inline-block;
                            font-size: 18px;
                            line-height: 31px;
                            font-weight: 700;
                            color: $whiteColor;
                            padding: 18px 40px 18px;
                            background: $secondaryColor;
                            &.active{
                                color: $bodyColor;
                                background: transparent;
                                &:hover{
                                    color: $whiteColor;
                                    background: $secondaryColor;
                                }
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .tab-content {
                    .tab-pane {
                        .tab-title {
                            margin-bottom: 25px;
                            font-size: 30px;
                            font-weight: 600;
                        }
                        .dsc-p {
                            margin: 0;
                        }
                        .reviews-grid {
                            .top-area {
                                margin-bottom: 5px;
                                p {
                                    margin-bottom: 23px;
                                }
                                h4 {
                                    font-weight: 400;
                                    color: unset;
                                }
                            }
                            .comment-form {
                                p.comment-notes {
                                    margin-bottom: 15px;
                                }
                                .placeholder-cntrl {
                                    label {
                                        font-weight: 600;
                                        min-width: 100px;
                                    }
                                    input {
                                        border: 1px solid rgba(54,54,54,.1);
                                        padding: 10px 15px;
                                        margin-bottom: 10px;
                                        outline: none;
                                        width: 50%;
                                    }
                                    textarea {
                                        display: block;
                                        width: 100%;
                                        padding: 10px 15px;
                                        height: 75px;
                                        border: 1px solid rgba(54,54,54,.1);
                                        margin-bottom: 20px;
                                        outline: none;
                                    }
                                }
                                .ratings {
                                    font-weight: 600;
                                    margin-bottom: 18px;
                                    display: block;
                                    ul {
                                        li {
                                            display: inline;
                                            i {
                                                color: #f5d109;
                                                font-weight: normal;
                                            }
                                        }
                                    }
                                }
                                .readon {
                                    font-weight: 500;
                                    font-size: 15px;
                                    padding: 13px 45px;
                                    border: none;
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .content-part{
            .loop-product{
                font-size: 25px;
                line-height: 25px;
                color: $titleColor;
                font-weight: 700;
            }
            .price{
                font-size: 20px;
                line-height: 34px;
                color: $primaryColor;
                font-weight: 800;
                display: block;
            }
            .desc-part{
                font-size: 15px;
                line-height: 26px;
                color: $bodyColor;
                font-weight: 400;
            }
            .add-cart{
                font-size: 15px;
                line-height: 25px;
                color: $whiteColor;
                font-weight: 400;
                background: $secondaryColor;
                text-transform: uppercase;
                border-radius:5px;
                padding: 10px 30px 10px;
                border: 2px solid $primaryColor;
                &:hover{
                    background: transparent;
                    color: $primaryColor;
                }
            }
        }
    }
    &.orange-style {
        .product-list{
            .content-desc{
                .product-title{
                   a{
                        &:hover{
                            color: $orangeColor;
                        }
                   }
                }
                .price{
                    color: $orangeColor;
                }
            }
        }
    }
}

/*----------------------------------------
    30. Single Shop Section CSS
----------------------------------------*/
.rs-single-shop {
    .single-product-image {
        .images-single{
            z-index: 1 !important;
          
        }
        img {
            width: 100%;
        }
    }
    .single-price-info {
        .product-title {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 12px;
        }
        .single-price {
            color: $primaryColor;
            font-weight: 600;
        }
        .some-text {
            margin-top: 15px;
            color: $bodyColor;
        }
        form {
            margin-bottom: 30px;
            input {
                height: 40px;
                width: 70px;
                line-height: 40px;
                text-align: center;
                padding-left: 10px;
                border: 1px solid rgba(54,54,54,.1);
                outline: none;
            }
        }
        p.category {
            margin: 0;
            padding-top: 25px;
            border-top: 1px solid #e6e6e6;
            font-size: 14px;
            span {
                font-weight: 700;
                padding-right: 10px;
            }
            a {
                color: $bodyColor;
                &:hover {
                    color: $primaryColor;
                }
            }
        }
        .tag {
            margin: 0;
            span {
                font-weight: 700;
            }
        }
    }
    .tab-area {
        margin-top: 50px;
        ul.nav-tabs {
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 32px !important;
            li {
                margin-right: 3px;
                button {
                    padding: 10px 30px;
                    display: inline-block;
                    border: none;
                    border-radius: 4px 4px 0 0;
                    font-size: 18px;
                    background: #f0f0f0;
                    color: $titleColor;
                    font-weight: 600;
                }
                &:hover,
                &.react-tabs__tab--selected {
                    button {
                        background: $primaryColor;
                        color: $whiteColor;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .tab-title {
            font-weight: 700;
            margin-bottom: 34px;
        }
        .dsc-p {
            margin: 0;
            color: $bodyColor;
        }
        .reviews-grid {
            .top-area {
                margin-bottom: 5px;
                p {
                    margin-bottom: 23px;
                }
                h6 {
                    font-size: 15px;
                    font-weight: 600;
                    color: $bodyColor;
                }
            }
            .comment-form {
                max-width: 600px;
                color: $bodyColor;
                p.comment-notes {
                    margin-bottom: 15px;
                }
                .placeholder-cntrl {
                    label {
                        font-weight: 600;
                        display: block;
                    }
                    input {
                        border: 1px solid rgba(54,54,54,.1);
                        padding: 10px 15px;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                    textarea {
                        width: 100%;
                        padding: 10px 15px;
                        height: 75px;
                        border: 1px solid rgba(54,54,54,.1);
                        margin-bottom: 20px;
                    }
                }
                .ratings {
                    font-weight: 600;
                    margin-bottom: 18px;
                    display: block;
                    ul {
                        li {
                            display: inline;
                            i {
                                color: $primaryColor;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }

    &.orange-style {
        .single-price-info .single-price,
        .single-price-info p.category a:hover,
        .tab-area .tab-content .tab-pane .reviews-grid .comment-form .ratings ul li i,
        .tab-area .reviews-grid .comment-form .ratings ul li i {
            color: $orangeColor;
        }
        .tab-area ul.nav-tabs li button:hover,
        .tab-area ul.nav-tabs li.react-tabs__tab--selected button {
            background: $orangeColor;
        }
    }
}

/*----------------------------------------
    31. Cart Section CSS
----------------------------------------*/
.rs-cart {
    .cart-wrap {
        color: $bodyColor;
        table.cart-table {
            border: 1px solid rgba(0,0,0,.1);
            width: 100%;
            border-collapse: collapse;
            td, th {
                padding: 25px;
                text-align: center;
                border: 1px solid #ccc;
            }
            th {
                border: none;
                font-size: 18px;
                padding: 25px;
                text-align: center;
                vertical-align: middle;
                font-weight: 700;
            }
            td {
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #e6e6e6;
            }
            .product-remove {
                a {
                    margin: 0 auto;
                    color: $titleColor;
                    display: block;
                    border-radius: 100%;
                    border: 1px solid #ddd;
                    font-size: 15px;
                    font-weight: 400;
                    height: 25px;
                    width: 25px;
                    line-height: 24px;
                    text-align: center;
                    &:hover {
                        background: #ff0000;
                        color: $whiteColor;
                    }
                }
            }
            .product-thumbnail {
                min-width: 32px;
                a {
                    img {
                        width: 80px;
                        height: auto;
                    }
                }
            }
            .product-name {
                a {
                    color: $bodyColor;
                    font-weight: 700;
                    &:hover {
                        color: $primaryColor;
                    }
                }
            }
            .product-price {
                font-weight: 700;
            }
            .product-quantity {
                input {
                    border: 1px solid #e6e6e6;
                    border-radius: 4px;
                    padding: 0 0 0 10px;
                    max-width: 65px;
                    margin: 0 auto;
                    outline: none;
                }
            }
            .action {
                .coupon {
                    input {
                        box-sizing: border-box;
                        border: 1px solid #d3ced2;
                        padding: 6px 6px 5px;
                        margin: 0 4px 0 0;
                        outline: none;
                        width: 320px;
                        border-radius: 4px;
                        height: 45px;
                        margin-right: 20px;
                        text-align: left;
                        padding-left: 22px;
                    }
                }
            }
        }
    }
    .cart-collaterals {
        width: 100%;
        overflow: hidden;
        .cart-totals {
            float: right;
            width: 48%;
            .title {
                font-weight: 700;
                color: $bodyColor;
                text-transform: capitalize;
            }
            table.cart-total-table {
                text-align: left;
                width: 100%;
                border-collapse: collapse;
                border-radius: 5px;
                tr {
                    border-top: 1px solid rgba(0,0,0,.1);
                    th {
                        font-size: 18px;
                        font-weight: 700;
                    }
                    td,th {
                        padding: 30px 10px;
                    }
                }
            }
        }
    }

    &.orange-style {
        .cart-wrap table.cart-table .product-name a:hover {
            color: $orangeColor;
        }
    }
}

/*----------------------------------------
    33. Checkout Section CSS
----------------------------------------*/
.rs-checkout {
    .checkout-title {
        margin-bottom: 30px;
        h3 {
            font-size: 26px;
            margin: 0;
        }
    }
    .coupon-toggle {
        .accordion__item {
            border-top: 3px solid $primaryColor;
            .accordion__heading {
                .accordion__button {
                    background: rgba(0,0,0,.03);
                    padding: .75rem 1.25rem;
                    .card-title {
                        margin: 0;
                        display: flex;
                        align-items: center;
                        span {
                            margin-right: 10px;
                            i {
                                margin-right: 10px;
                            }
                        }
                        .accordion-toggle {
                            background: unset;
                            border: none;
                            color: $titleColor;
                            transition: $transition;
                            outline: none;
                            cursor: pointer;
                            &:hover {
                                color: $primaryColor;
                            }
                        }
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .accordion__panel {
                padding: 0;
                .card-body {
                    border: 1px solid #d3ced2;
                    padding: 20px 20px 50px 20px;
                    margin-top: 2em;
                    text-align: left;
                    .coupon-code-input {
                        width: 47%;
                        float: left;
                        margin-right: 50px;
                        input {
                            width: 100%;
                            height: 45px;
                            outline: none;
                            padding: 10px 18px;
                            color: $bodyColor;
                            border: 1px solid rgba(54, 54, 54, 0.1);
                        }
                    }
                }
            }
        }
    }
    .full-grid { 
        margin-top: 25px;   
        .form-content-box {
            margin-bottom: 50px;
            .form-group {
                label {
                    line-height: 2;
                    display: block;
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                select {
                    color: #666666;
                    opacity: .5;
                    padding-left: 8px;
                    padding-right: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border: 1px solid #ccc;
                    border-radius: 0;
                    height: 45px;
                    line-height: 45px;
                    cursor: pointer;
                    width: 100%;
                    outline: none;
                }
                textarea {
                    height: 4em;
                    line-height: 1.5;
                    display: block;
                    box-shadow: none;
                    width: 100%;
                    border: 1px solid rgba(54, 54, 54, 0.1);
                    padding: 10px 18px;
                    margin: 0;
                    outline: none;
                }
                .form-control-mod {
                    border: 1px solid rgba(54, 54, 54, 0.1);
                    height: 45px;
                    padding: 10px 18px;
                    width: 100%;
                    margin: 0;
                    outline: none;
                    line-height: normal;
                    border-radius: unset;
                    &.margin-bottom {
                        margin-bottom: 10px !important;
                    }
                }
            }
        }
        .ordered-product {
            table {
                width: 100%;
                tr {
                    th {
                        border: 1px solid #ccc;
                        padding: 9px 12px;
                    }
                    td {
                        border: 1px solid #ccc;
                        padding: 6px 12px;
                    }
                }
            }
        }

        .payment-method {
            .top-area {
                border-bottom: 1px solid #d3ced2;
                .payment-co {
                    margin-bottom: 20px;
                    span {
                        font-weight: 600;
                        margin-right: 10px;
                    }
                }
                .p-msg {
                    position: relative;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 1em;
                    margin: 1em 0 2em;
                    font-size: .92em;
                    border-radius: 2px;
                    line-height: 1.5;
                    background-color: #f9f9f9;
                    &:before {
                        content: '';
                        display: block;
                        border: 1em solid #f9f9f9;
                        border-right-color: transparent;
                        border-left-color: transparent;
                        border-top-color: transparent;
                        position: absolute;
                        top: -.75em;
                        left: 0;
                        margin: -1em 0 0 2em;
                    }
                }
            }
        }
    }

    &.orange-style {
        .coupon-toggle .accordion__item {
            border-color: $orangeColor;
        }
        .coupon-toggle .accordion__item .accordion__heading .accordion__button .card-title .accordion-toggle:hover {
            color: $orangeColor;
        }
    }
}
body .rs-checkout .full-grid{
    color: $bodyColor;
}

/*----------------------------------------
    34. Login Section CSS
----------------------------------------*/
.rs-login{
    .noticed{
        background: #f9f8f8;
        max-width: 800px;
        padding: 70px;
        margin: 0 auto;
        .main-part{
            max-width: 400px;
            margin: 0 auto;
            text-align: center;
            border-radius: 5px;
            .method-account{
                .login{
                    font-size: 40px;
                    line-height: 50px;
                    font-weight: 700;
                    color: $titleColor;
                }
                label{
                    font-size: 15px;
                    line-height: 30px;
                    font-weight: 600;
                    color: $bodyColor;
                }
                input{
                    margin-bottom: 30px;
                    font-size: 15px;
                    font-weight: 400;
                    color: $bodyColor;
                    width: 100%;
                    display: block;
                    border: none;
                    height: 45px;
                    padding: 10px 18px;
                    border-radius: 5px;
                    box-shadow: 0 0 30px #eee;
                }
               
                span{
                   font-size: 15px;
                   line-height: 30px;
                   font-weight: 600;
                   color: $bodyColor;
                   padding-left: 10px;
                }
                .last-password{
                    p{
                        color: $bodyColor;
                        margin: 0;
                        a{ 
                            font-size: 15px;
                            line-height: 26px;
                            font-weight: 400;
                            color: $orangeColor;
                            &:hover{
                                color: $bodyColor;
                            }
                        }
                    }
                }               
            }
        }
    }
}

/* -----------------------------------
    35. Register Section CSS
-------------------------------------*/
.register-section{
    position:relative;
    .register-box{
        position:relative;
        max-width:970px;
        margin:0 auto;
        padding:45px 40px 35px;
        background: #f9f8f8;
        .styled-form{
            max-width: 600px;
            margin: 0 auto;
            .form-group{ 
                .title-box{
                    position:relative;
                    text-align:center;
                    margin-bottom:32px;
                    h2{
                        position:relative;
                        color:#03382e;
                        font-weight:700;
                        line-height:1.3em;
                    }
                    .text{
                        position:relative;
                        color:#03382e;
                        font-size:16px;
                        font-weight:400;
                        line-height:1.8em;
                        margin-top:12px;
                    }
                }
            }
        }
    }
}

// My Account
.rs-my-account{
    .title{
        font-size: 42px;
        line-height: 52px;
        font-weight: 600;
        color: $titleColor;
        margin: 0;
    }
    .rs-login{
        border: 1px solid rgba(54,54,54,.1);
        padding: 20px;
        text-align: left;
        border-radius: 5px;
        .form-group{
            .form-part {
                label{
                    display: block;
                    font-size: 15px;
                    line-height: 30px;
                    font-weight: 600;
                    color: $bodyColor;
                    span{
                        color: $orangeColor;
                    }
                }
                input{
                    width: 100%;
                    margin: 0;
                    outline: 0;
                    line-height: normal;
                    border: 1px solid rgba(54,54,54,.1);
                    height: 45px;
                }
            }
            .btns-part {
                button {
                    margin-right: 15px;
                }
                span {
                    margin-left: 5px;
                }
            }
        }
        .last-password{
            a{
                font-size: 15px;
                line-height: 26px;
                font-weight: 400;
                color: $orangeColor;
            }
        }
        p{
            font-size: 15px;
            line-height: 26px;
            font-weight: 400;
            color: $bodyColor;
            margin-bottom: 14px;
            a{  
                font-size: 15px;
                line-height: 26px;
                font-weight: 400;
                color: $orangeColor;
            }
        }
    }
}


/* Styled Form */

.styled-form{
    position:relative;
    .form-group{
        position:relative;
        .eye-icon{
            position:absolute;
            right:20px;
            top:52px;
            color:#222222;
            font-size:18px;
            z-index:1;
            opacity:0.5;
        }
        label{
            position:relative;
            color:#626262;
            font-size:16px;
            font-weight:400;
            margin-bottom:15px;
        }
        input{
            position:relative;
            height:50px;
            padding:6px 30px;
            width:100%;
            color:#222222;
            font-size:16px;
            border-radius:0px;
            transition:all 500ms ease;
            -moz-transition:all 500ms ease;
            -webkit-transition:all 500ms ease;
            -ms-transition:all 500ms ease;
            -o-transition:all 500ms ease;
            border: none;
            box-shadow: 0 0 30px #eee;
            &:focus{
                border-color:#ff6262;
            }
        }
        textarea{
            position:relative;
            height:150px;
            width:100%;
            resize:none;
            padding:15px 15px;
            border-radius:50px;
            border:1px solid #f2f2f2;
            transition:all 500ms ease;
            -moz-transition:all 500ms ease;
            -webkit-transition:all 500ms ease;
            -ms-transition:all 500ms ease;
            -o-transition:all 500ms ease;
        }
        .users{
            position:relative;
            color:#626262;
            font-size:16px;
            text-align:center;
            a{
                position:relative;
                color:$orangeColor;
                font-weight:600;
                text-decoration:underline;
                &:hover {
                    color: darken($orangeColor,10%);
                }
            }
        }
        /* CheckBox */
        .check-box{
            position:relative;
            margin-bottom:3px;
            margin-top:7px;
            label{
                position: relative;
                display: block;
                width: 100%;
                line-height: 22px;
                padding: 4px 10px 0px;
                padding-left: 25px;
                background: #ffffff;
                font-size: 16px;
                font-weight: 400;
                color: #626262;
                cursor: pointer;
                margin-bottom: 0;
                -webkit-transition: all 300ms ease;
                -moz-transition: all 300ms ease;
                -ms-transition: all 300ms ease;
                -o-transition: all 300ms ease;
                transition: all 300ms ease;
                font-family: 'Poppins', sans-serif;
                &:before{
                    position: absolute;
                    left: 0;
                    top: 6px;
                    height:15px;
                    width: 15px;
                    background: #ffffff;
                    content: "";
                    border-radius: 3px;
                    -webkit-transition: all 300ms ease;
                    -moz-transition: all 300ms ease;
                    -ms-transition: all 300ms ease;
                    -o-transition: all 300ms ease;
                    transition: all 300ms ease;
                    border:1px solid #21a7d0;
                }
                &:after {
                    position: absolute;
                    left: 0px;
                    top: 0;
                    height: 15px;
                    line-height: 15px;
                    max-width: 0;
                    font-size: 14px;
                    color: #ffffff;
                    font-weight: 800;
                    overflow: hidden;
                    background: transparent;
                    text-align: center;
                    font-family: 'FontAwesome';
                    -webkit-transition: max-width 500ms ease;
                    -moz-transition: max-width 500ms ease;
                    -ms-transition: max-width 500ms ease;
                    -o-transition: max-width 500ms ease;
                    transition: max-width 500ms ease;
                }
            }
            input[type="checkbox"]:checked + label{
                border-color: #25a9e0;
            }
        }
        /* RadioBox */
        .radio-box{
            position:relative;
            margin-bottom:3px;
            margin-top:7px;
            label{
                position: relative;
                display: block;
                height: 30px;
                width: 100%;
                line-height: 9px;
                padding: 4px 10px 0px;
                padding-left: 25px;
                background: #ffffff;
                font-size: 16px;
                font-weight: 400;
                color: #626262;
                cursor: pointer;
                margin-bottom: 0;
                font-family: 'Poppins', sans-serif;
                -webkit-transition: all 300ms ease;
                -moz-transition: all 300ms ease;
                -ms-transition: all 300ms ease;
                -o-transition: all 300ms ease;
                transition: all 300ms ease;
                &:before{
                    position: absolute;
                    left: 0;
                    top: 0;
                    height:15px;
                    width: 15px;
                    background: #ffffff;
                    content: "";
                    border-radius: 3px;
                    -webkit-transition: all 300ms ease;
                    -moz-transition: all 300ms ease;
                    -ms-transition: all 300ms ease;
                    -o-transition: all 300ms ease;
                    transition: all 300ms ease;
                    border:1px solid #21a7d0;
                }
                &:after {
                    position: absolute;
                    left: 0px;
                    top: 0;
                    height: 15px;
                    line-height: 15px;
                    max-width: 0;
                    font-size: 14px;
                    color: #ffffff;
                    font-weight: 800;
                    overflow: hidden;
                    background: transparent;
                    text-align: center;
                    font-family: 'FontAwesome';
                    -webkit-transition: max-width 500ms ease;
                    -moz-transition: max-width 500ms ease;
                    -ms-transition: max-width 500ms ease;
                    -o-transition: max-width 500ms ease;
                    transition: max-width 500ms ease;
                }
            }
        }
    }
    .forgot{
        position:relative;
        font-weight:500;
        color:#626262;
        font-size:16px;
        margin-top:10px;
        display:inline-block;
        text-decoration:underline;
    }
}


.styled-form .form-group .form-group .check-box label {
    padding-left: 30px;
    padding-top: 1px;
    cursor: pointer;
}

.styled-form .form-group .check-box input[type="checkbox"] {
    display: none;
}

.styled-form .form-group .check-box input[type="checkbox"]:checked + label:before{
    border: 5px solid #21a7d0;
    background: #ffffff;
}

.styled-form .form-group .check-box input[type="checkbox"]:checked + label:after {
    max-width: 20px;
    opacity: 1; 
}

.styled-form .form-group .radio-box input[type="radio"]:checked + label{
    border-color: #25a9e0;
}

.styled-form .form-group .form-group .radio-box label {
    padding-left: 30px;
    padding-top: 1px;
    cursor: pointer;
}

.styled-form .form-group .radio-box input[type="radio"] {
    display: none;
}

.styled-form .form-group .radio-box input[type="radio"]:checked + label:before{
    border: 5px solid #21a7d0;
    background: #ffffff;
}

.styled-form .form-group .radio-box input[type="radio"]:checked + label:after {
    max-width: 20px;
    opacity: 1; 
}

.rs-free-contact{
    background: $greenColor;
    padding: 50px 50px 50px 50px;
    .from-control{
        width: 100%;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #0C8B51;
        background-color: #127C4B;
        border-radius: 3px;
        padding: 10px 18px;
    }
    textarea {
        height: 140px;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $whiteColor;
      opacity: 1;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $whiteColor;
      opacity: 1;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: $whiteColor;
      opacity: 1;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $whiteColor;
      opacity: 1;
    } 
}



// Responsive CSS
@media #{$md} {
    .rs-cart {
        .cart-wrap {
            table {
                &.cart-table {
                    .action {
                        .coupon {
                            input {
                                width: calc(100% - 204px);
                            }
                        }
                    }
                }
            }
        }
    }
    .rs-login .noticed {
        padding: 50px 20px;
    }
    .register-section .register-box {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media #{$sm} {
    .rs-cart {
        .cart-wrap {
            table {
                &.cart-table {
                    th {
                        display: none;
                    }
                    td {
                        display: block;
                        &.action {
                            .update-cart {
                                button {
                                    width: 100%;
                                }
                            }
                            .coupon {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
        .cart-collaterals {
            .cart-totals {
                float: none;
                width: 100%;
            }
        }
    }
    .rs-shop-part {
        .from-control {
            float: none;
        }
    }
}
@media #{$xs} {
    .rs-checkout {
        .coupon-toggle {
            .accordion__item {
                .accordion__panel {
                    .card-body {
                        .coupon-code-input {
                            width: 100%;
                            float: none;
                            margin-bottom: 20px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
@media #{$mobile} {
    .rs-checkout {
        .coupon-toggle {
            .accordion__item {
                .accordion__heading {
                    .accordion__button {
                        .card-title {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .rs-cart {
        .cart-wrap {
            table {
                &.cart-table {
                    .action {
                        .coupon {
                            margin-bottom: 25px;
                            padding-bottom: 25px;
                            border-bottom: 1px solid #e6e6e6;
                            input {
                                width: calc(100%);
                                width: 100%;
                                margin-bottom: 15px;
                                margin-right: 0 !important;
                            }
                            button {
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .cart-collaterals {
            .cart-totals {
                .wc-proceed-to-checkout {
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
    .rs-free-contact{
        padding-left: 20px;
        padding-right: 20px;
    }
}